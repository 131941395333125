import React from 'react';
import numBg from 'images/pricing/french/background.png';

import bgFR from './index.module.scss';

const PricingBgFrench: React.FC = () => {
  const bgLeftImages = (
    <div className={bgFR.bgNumber}>
      <img src={numBg} alt="numberbg" />
    </div>
  );

  return (
    <>
      {bgLeftImages}
    </>
  );
};

export default PricingBgFrench;
