import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import Background from './Backgorund';
import List, { ListType } from './List';
import Price, { PriceType } from './Price';

import styles from './Plan.module.scss';

interface PricingPlanProps {
  id: string;
  listData: ListType;
  price: PriceType;
  lastListItemInvisible?: boolean;
}

const PricingPlan: React.FC<PricingPlanProps> = ({
  id,
  listData,
  price,
  lastListItemInvisible = false,
}) => (
  <Container className={styles.container}>
    <Background id={id}>
      <div className={styles.planContainer}>
        <Row>
          <Col>
            <List data={listData} lastItemInvisible={lastListItemInvisible} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Price data={price} />
          </Col>
        </Row>
      </div>
    </Background>
  </Container>
);

export default PricingPlan;
