import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import cmnStyles from 'styles/pages.module.scss';
import styles from './Intro.module.scss';

const PricingIntro: React.FC = () => {
  const colTitle = `${cmnStyles.alignSectionVerticallyCenter} ${styles.colTitle}`;
  return (
    <Container className={styles.main}>
      <Row>
        <Col
          className={colTitle}
        >
          <h1 className={`${cmnStyles.articleTitle} ${styles.title}`}>
            <FormattedMessage id="components.pricing.intro" />
          </h1>
        </Col>
      </Row>
    </Container>
  );
};

export default PricingIntro;
