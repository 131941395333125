import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TextSizes } from 'components/Pricing/Plans';

import styles from './List.module.scss';

interface PricingPlanListProps {
  data: ListType;
  lastItemInvisible?: boolean;
}

export type ListType = {
  title: string;
  titleSize: string;
  list: Array<string>;
}

const PricingPlanList: React.FC<PricingPlanListProps> = ({
  data, lastItemInvisible = false,
}) => {
  const { list } = data;
  const listItems = list.length > 0 ? list.map((item, index) => {
    const invLastItemCl = lastItemInvisible && index === list.length - 1 ? styles.itemInvisible : '';
    return (
      <li key={item} className={invLastItemCl}>
        <span>
          <FormattedMessage id={item} />
        </span>
      </li>
    );
  }) : <span />;
  const tStyle = data.titleSize === TextSizes.Normal ? styles.titleNormal : styles.titleSmaller;
  return (
    <div className={styles.mainContainer}>
      <h2 className={tStyle}>
        <FormattedMessage id={data.title} />
      </h2>
      <ul className={styles.list}>
        {listItems}
      </ul>
    </div>
  );
};

export default PricingPlanList;
