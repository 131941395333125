/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { withPrefix } from 'gatsby';
import { useIntl, FormattedMessage } from 'react-intl';
import GeneralSettings from 'helpers/constants/settings';
import ContentModal from 'components/UI/ContentModal';
import { isMobileOS, isTablet } from 'helpers/utils/mobile';
import { getDownloadAppLink } from 'helpers/utils/url';

import ActionLinkButton from 'components/UI/Buttons/ActionLinkButton';
import { TextSizes } from 'components/Pricing/Plans';
import styles from './Price.module.scss';

interface PricingPlanPriceProps {
  data: PriceType;
  useAlignPad?: boolean;
}

export type PriceType = {
  title: string;
  titleSize: string;
  p1: string;
  p2: string;
}

const PricingPlanPrice: React.FC<PricingPlanPriceProps> = ({
  data,
}) => {
  const DETAILS_VISIBLE = false;
  const [isMobile, setMobile] = useState(false);
  const [pdfModalVis, setShowPDFModal] = useState(false);
  const [screenWidth, setScreenWidth] = useState(1024);
  let usePlainLink = false;

  const { locale } = useIntl();
  const pricingPDF = `pricing_conditions_${locale}.pdf`;
  const tSize = data.titleSize === TextSizes.Normal ? styles.titleNormal : styles.titleSmaller;
  const pdfURL = withPrefix(`/downloads/${pricingPDF}`);
  const tryForFreeLink = getDownloadAppLink(locale);

  const updateWidth = (): void => {
    usePlainLink = isMobileOS();
    const isMob = (isMobileOS() && !isTablet())
      || window.innerWidth < GeneralSettings.MOBILE_VIEW.PRICING_PDF;
    setMobile(isMob);
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return (): void => window.removeEventListener('resize', updateWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const windowWidth = screenWidth / 1.4;
  const openPDF = (): void => {
    if (usePlainLink) {
      window.open(
        withPrefix(pdfURL),
        'Pricing details',
        `height=${screenWidth / 2},width=${windowWidth},
         top=200,left=${(screenWidth - windowWidth) / 1.5}`,
      );
    } else {
      setShowPDFModal(true);
    }
  };

  const pdfDesktop = (
    <a
      aria-label="PDF details"
      role="button"
      tabIndex={0}
      onClick={openPDF}
      onKeyPress={openPDF}
    >
      <FormattedMessage id="components.pricing.detailsLink" />
    </a>
  );

  const pdfMobile = (
    <a
      href={pdfURL}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FormattedMessage id="components.pricing.detailsLink" />
    </a>
  );

  const pdfLink = screenWidth > GeneralSettings.MOBILE_VIEW.PRICING_PDF ? pdfDesktop : pdfMobile;
  const buttonPadWidth = isMobile ? '8px 28px' : '8px 36px';
  const pdfDetails = DETAILS_VISIBLE ? (
    <div className={styles.pricingDetails}>
      {pdfLink}
    </div>
  ) : null;

  return (
    <div className={styles.mainContainer}>
      <div className={styles.price}>
        <h4>
          <FormattedMessage id="components.pricing.startingFrom" />
        </h4>
        <h2 className={tSize}>
          {data.title}
        </h2>
      </div>
      <div className={styles.description}>
        <p>
          <FormattedMessage id={data.p1} />
        </p>
        <p>
          <FormattedMessage id={data.p2} />
        </p>
      </div>
      <div className={styles.button}>
        <ActionLinkButton
          url={tryForFreeLink}
          customStyle={{ padding: buttonPadWidth }}
        >
          <FormattedMessage id="components.pricing.findOutMore" />
        </ActionLinkButton>
      </div>
      {pdfDetails}

      <ContentModal
        show={pdfModalVis}
        hide={(): void => { setShowPDFModal(false); }}
        title="PDF Pricing"
        contentUrl={pdfURL}
      >
        <FormattedMessage id="components.pricing.detailsLink" />
      </ContentModal>
    </div>
  );
};

export default PricingPlanPrice;
