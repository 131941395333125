import React from 'react';
import leftSm from 'images/pricing/international/background.png';

import bgIntl from './index.module.scss';

const PricingBgIntl: React.FC = () => {
  const bgLeftImages = (
    <>
      <div className={bgIntl.bgLeftSm}>
        <img src={leftSm} alt="leftBallSmall" />
      </div>
    </>
  );

  return (
    <>
      {bgLeftImages}
    </>
  );
};

export default PricingBgIntl;
