import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PricingPlan from '../Plan';
import styles from './Plans.module.scss';

const root = 'components.pricing.plans';
const french = `${root}.french`;
const international = `${root}.international`;

export enum TextSizes {
  Normal = 'normal',
  Smaller = 'smaller'
}

const PlanFR = {
  id: 'french',
  title: `${french}.title`,
  titleSize: TextSizes.Normal,

  list: [ // list of constants to translate
    `${french}.item1`,
    `${french}.item2`,
    `${french}.item3`,
  ],
  price: {
    title: '3,49€',
    titleSize: TextSizes.Normal,
    p1: `${french}.right1`,
    p2: `${french}.right2`,
  },
};

const PlanIntl = {
  id: 'international',
  title: `${international}.title`,
  titleSize: TextSizes.Smaller,

  list: [
    `${international}.item1`,
    `${international}.item2`,
    `${international}.item3`,
  ],
  price: {
    title: '4.49€',
    titleSize: TextSizes.Normal,
    p1: `${french}.right1`,
    p2: `${international}.right2`,
  },
};
const PricingPlans: React.FC = () => (
  <Container className={styles.mainContainer}>
    <Row>
      <Col
        xs={{ span: 12, order: 1 }}
        sm={{ span: 12, order: 1 }}
        md={{ span: 12, order: 1 }}
        lg={{ span: 6, order: 1 }}
        xl={{ span: 6, order: 1 }}
      >
        <PricingPlan
          id={PlanFR.id}
          listData={{ title: PlanFR.title, titleSize: PlanFR.titleSize, list: PlanFR.list }}
          price={PlanFR.price}
        />
      </Col>
      <Col
        xs={{ span: 12, order: 2 }}
        sm={{ span: 12, order: 2 }}
        md={{ span: 12, order: 2 }}
        lg={{ span: 6, order: 2 }}
        xl={{ span: 6, order: 2 }}
      >
        <PricingPlan
          id={PlanIntl.id}
          listData={{ title: PlanIntl.title, titleSize: PlanIntl.titleSize, list: PlanIntl.list }}
          price={PlanIntl.price}
        />
      </Col>
    </Row>
  </Container>
);

export default PricingPlans;
